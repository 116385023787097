<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">Add Campaign Templates</div>
        <div class="
                        col-lg-7 col-12
                        text-end
                        d-flex
                        justify-content-end
                        custom-flex-cloumn-mob
                      ">
            <button type="button" @click="newtemplateModalOpen()" class="header-btn-outer btn btn-primary">
                Add Campaign Template
            </button>
        </div>
    </div>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4 " id="intelligent-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#intelligentcampaigndetail" type="button" role="tab" aria-controls="vehicle-details"
                aria-selected="false" @click="redirectIntelligentCampaignApi()">
                Intelligent
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4" id="whatsapp-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#whatsappcampaigndetail" type="button" role="tab" aria-controls="customer-details"
                aria-selected="false" @click="redirectWhatsAppCampaignApi()">
                WhatsApp
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4" id="imessage-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#imessagecampaigndetail" type="button" role="tab" aria-controls="customer-details"
                aria-selected="false" @click="redirectiMessageCampaignApi()">
                RCS / iMessage
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4 active" id="sms-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#smscampaigndetail" type="button" role="tab" aria-controls="customer-details"
                aria-selected="true">
                SMS
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4" id="email-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#emailcampaigndetail" type="button" role="tab" aria-controls="customer-details"
                aria-selected="false" @click="redirectEmailCampaignApi()">
                Email
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4" id="audio-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#audiocampaigndetail" type="button" role="tab" aria-controls="customer-details"
                aria-selected="false" @click="redirectAudioCallCampaignApi()">
                Audio Call
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link px-4" id="notification-campaign-tab" data-bs-toggle="tab"
                data-bs-target="#notificationcampaigndetail" type="button" role="tab" aria-controls="customer-details"
                aria-selected="false" @click="redirectNotificationCampaignApi()">
                Push Notification
            </button>
        </li>
    </ul>
    <div class="tab-content mt-2" id="myTabContent">
        <div class="tab-pane fade" id="intelligentcampaigndetail" role="tabpanel"
            aria-labelledby="intelligent-campaign-tab">

        </div>
        <div class="tab-pane fade" id="whatsappcampaigndetail" role="tabpanel" aria-labelledby="whatsapp-campaign-tab">

        </div>
        <div class="tab-pane fade" id="imessagecampaigndetail" role="tabpanel" aria-labelledby="imessage-campaign-tab">

        </div>
        <div class="tab-pane fade show active" id="smscampaigndetail" role="tabpanel"
            aria-labelledby="sms-campaign-tab">
            <div class="custom-ultima-datatable" style="height: calc(100vh - 188px)">
                <DataTable :value="smsCampaignList" :scrollable="true" scrollHeight="flex" columnResizeMode="fit"
                    :paginator="true" :lazy="true" :rowHover="true" :totalRecords="smsRecords" :rows="30" dataKey="aj1"
                    @page="changePage($event)"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    :loading="loading">
                    <template v-if="!loading" #empty>No records found.</template>

                    <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
                    <Column field="Name" header="Alias Name" headerStyle="width: 30%" bodyStyle="width: 30%">

                        <template #body="{ data }">
                            <div>{{ data.cj4 ? data.cj4 : "N/A" }}</div>
                        </template>
                    </Column>
                    <Column field="headername" header="Header Name" headerStyle="width: 15%" bodyStyle="width: 15%">

                        <template #body="{ data }">
                            <div>{{ data.cj3 ? data.cj3 : "N/A" }}</div>
                        </template>
                    </Column>
                    <Column field="message" header="Template Message" headerStyle="width: 40%" bodyStyle="width: 40%">

                        <template #body="{ data }">
                            <div class="p-text-left">
                            <span v-if="!activate_read_key_arr.includes(data.cj1)" v-html="replacebrake(data.cj7.slice(0, 135))"></span>
                        <a v-if="data.cj7.length >= 135 && !activate_read_key_arr.includes(data.cj1)"
                            @click="activateReadMore(data.cj1)" class="showmorebody ps-1">read more</a>
                        <span v-if="activate_read_key_arr.includes(data.cj1)" v-html="replacebrake(data.cj7)"></span>
                        <span>
                            <a v-if="data.cj7.length >= 135 && activate_read_key_arr.includes(data.cj1)"
                                @click="deactivateReadMore(data.cj1)" class="showlessbody ps-1">read less</a>
                        </span>
                    </div>
                        </template>
                    </Column>
                    <Column field="status" header="Status" headerStyle="width: 15%" bodyStyle="width: 15%" class="justify-content-end" >

                        <template #body="{ data }">
                            <div class="d-flex align-items-end">
                                <InputSwitch v-model="data.cj12" :true-value="dynamicTrueActiveValue"
                                    :false-value="dynamicFalseInactiveValue" @change="confirmModalOpen(data)" />
                                <button type="button" title="View Details" class="btn custom-outline-view-btn ms-2"
                                    @click="viewSmsDetailModalOpen(data)">
                                    <i class="pi pi-eye call-btn-color"></i>
                                </button>
                                <button type="button" title="Edit" class="btn custom-outline-view-btn ms-2"
                                    @click="editSmsTemplateModalOpen(data)">
                                    <i class="pi pi-pencil call-btn-color"></i>
                                </button>
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <div class="tab-pane fade" id="emailcampaigndetail" role="tabpanel" aria-labelledby="email-campaign-tab">

        </div>
        <div class="tab-pane fade" id="audiocampaigndetail" role="tabpanel" aria-labelledby="audio-campaign-tab">

        </div>
        <div class="tab-pane fade" id="notificationcampaigndetail" role="tabpanel"
            aria-labelledby="notification-campaign-tab">

        </div>
    </div>
    <!-- Add Template modal start here -->
    <div class="modal-mask" v-if="$store.state.newtempaltemodalstatus">
        <div class="modal-dialog modal-xl modal-dialog-centered
            custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Add Campaign Template</h5>
                    <button type="button" class="btn-close" @click="newtemplateModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-12 custom-start-menu-col">
                            <ul class="nav nav-tabs custom-vertical-modal-tab flex-column" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="intelligent-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#intelligentcampaigntarget" type="button" role="tab"
                                        aria-controls="intelligent-campaign" aria-selected="false"
                                        @click="selectedtab((e = 1))">
                                        Intelligent Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="whatsapp-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#whatsappcampaigntarget" type="button" role="tab"
                                        aria-controls="whatsapp-campaign" aria-selected="false"
                                        @click="selectedtab((e = 2))">
                                        WhatsApp Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="imessage-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#imessagecampaigntarget" type="button" role="tab"
                                        aria-controls="imessage-campaign" aria-selected="false"
                                        @click="selectedtab((e = 3))">
                                        RCS / iMessage Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="sms-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#smscampaigntarget" type="button" role="tab"
                                        aria-controls="sms-campaign" aria-selected="true" @click="selectedtab((e = 4))">
                                        SMS Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="email-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#emailcampaigntarget" type="button" role="tab"
                                        aria-controls="email-campaign" aria-selected="false"
                                        @click="selectedtab((e = 5))">
                                        Email Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="audio-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#audiocampaigntarget" type="button" role="tab"
                                        aria-controls="audio-campaign" aria-selected="false"
                                        @click="selectedtab((e = 6))">
                                        Audio Call Campaign
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="notification-campaign-tab" data-bs-toggle="tab"
                                        data-bs-target="#notificationcampaigntarget" type="button" role="tab"
                                        aria-controls="notification-campaign" aria-selected="false"
                                        @click="selectedtab((e = 7))">
                                        Push Notification Campaign
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-9 col-md-9 col-12 custom-end-body-col">
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade" id="intelligentcampaigntarget" role="tabpanel"
                                    aria-labelledby="intelligent-campaign-tab">
                                    <div v-if="selected_tab == 1">
                                        <IntelligentCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="whatsappcampaigntarget" role="tabpanel"
                                    aria-labelledby="whatsapp-campaign-tab">
                                    <div v-if="selected_tab == 2">
                                        <WhatsAppCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="imessagecampaigntarget" role="tabpanel"
                                    aria-labelledby="imessage-campaign-tab">
                                    <div v-if="selected_tab == 3">
                                        <iMessageCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane show active" id="smscampaigntarget" role="tabpanel"
                                    aria-labelledby="sms-campaign-tab">
                                    <div v-if="selected_tab == 4">
                                        <SMSCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="emailcampaigntarget" role="tabpanel"
                                    aria-labelledby="email-campaign-tab">
                                    <div v-if="selected_tab == 5">
                                        <EmailCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="audiocampaigntarget" role="tabpanel"
                                    aria-labelledby="audio-campaign-tab">
                                    <div v-if="selected_tab == 6">
                                        <AudioCallCampaignTab />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="notificationcampaigntarget" role="tabpanel"
                                    aria-labelledby="notification-campaign-tab">
                                    <div v-if="selected_tab == 7">
                                        <PushNotificationCampaignTab />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add Template modal end here -->
    <!-- confirmation update Voter Card as a primary modal start here -->
    <div class="modal-mask" v-if="confirm_popup_status">
        <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal">
            <div class="modal-content text-center">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="confirm-dialog-header">Confirmation</div>
                        </div>
                        <div class="col-12">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                                </div>
                                <div class="flex-grow-1 ms-3 confirm-dialog-body">
                                    Are you sure you want to proceed?
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 text-end">
                        <button type="button" :disabled="showconfirmprivoterloaderbtn" class="btn confirm-modal-no-btn"
                            @click="confirmModalClose()">
                            No
                        </button>
                        <button type="button" class="btn confirm-modal-yes-btn" @click="changeStatus()"
                            :disabled="showconfirmprivoterloaderbtn">
                            <span v-if="!showconfirmprivoterloaderbtn">Yes</span>

                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="showconfirmprivoterloaderbtn">
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- confirmation update Voter Card as a primary modal end here -->
    <!-- View SMS Details start here -->
    <div class="modal-mask" v-if="viewsmstemplatedetailmodalstatus">
        <div class="modal-sm modal-dialog-scrollable modal-fullscreen
        ms-auto
        custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">{{ this.headeraliasename }}</h5>
                    <button type="button" class="btn-close" @click="viewSmsDetailModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="voter-exit-box-outer mb-3">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Template Name</label>
                                    <div class="from-label-value">
                                        {{ smsTemplateBody.cj5 ? smsTemplateBody.cj5 : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Header Name</label>
                                    <div class="from-label-value">
                                        {{ smsTemplateBody.cj3 ? smsTemplateBody.cj3 : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Vendor Template Id</label>
                                    <div class="from-label-value text-capitalize">
                                        {{ smsTemplateBody.cj6 ? smsTemplateBody.cj6 : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-12" v-if="smsTemplateBody.cj8">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Language</label>
                                    <div class="from-label-value text-capitalize">
                                        {{ smsTemplateBody.cj8 ? smsTemplateBody.cj8 : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Status</label>
                                    <div class="from-label-value">
                                        <span v-if="smsTemplateBody.cj12 == 1">Active</span>
                                        <span v-else>Inactive</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="voter-exit-box-outer">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-12">
                                <label class="form-label"><span v-html="replacebrake(smsTemplateBody.cj7)"></span></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- View SMS Details End here -->
    <!-- Edit SMS Template modal stat here -->
    <div class="modal-mask" v-if="editSmsTemplateDialog">
        <div class="modal-dialog modal-xl modal-dialog-centered custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Edit SMS Campaign Template</h5>
                    <button type="button" class="btn-close" @click="editSmsTemplateModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="modal-body-fixed-height">
                        <div v-if="!showmodalloader">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Sender ID / Header<span class="text-danger">*</span></label>
                                        <Multiselect v-model="senderid" :options="senderidlist" :searchable="true" label="label"
                                            placeholder="Select Sender ID / Header" class="multiselect-custom text-capitalize" :canClear="true"
                                            :closeOnSelect="true" noOptionsText="No Result Found" :object="true" />
                                        <div class="custom-error" v-if="v$.senderid.$error">
                                            {{ v$.senderid.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Vendor Template ID<span
                                                class="text-danger">*</span></label>
                                        <input type="text" v-model="vendortemplateid" class="form-control"
                                            placeholder="Enter Vendor Template ID" @paste="onPasteMobile" @keypress="onlyNumber"
                                            autocomplete="off" />
                                        <div class="custom-error" v-if="v$.vendortemplateid.$error">
                                            {{ v$.vendortemplateid.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-8 col-md-8 col-12">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="card-template">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <div class="custom-form-group">
                                                            <label class="form-label">Template Name <span class="text-danger">*</span></label>
                                                            <input type="text" v-model="templatename" v-on:keyup="checkTemplateName()" class="form-control" placeholder="Enter Template Name"
                                                                maxlength="250" autocomplete="off" />
                                                            <div class="custom-error" v-if="v$.templatename.$error">
                                                                {{ v$.templatename.$errors[0].$message }}
                                                            </div>
                                                            <div class="custom-error" v-if="duplicateTemplateMsg">
                                                                {{ duplicateTemplateMsg }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <div class="custom-form-group">
                                                            <label class="form-label">Alias Name <span class="text-danger">*</span></label>
                                                            <input type="text" v-model="aliasname" class="form-control" placeholder="Enter Alias Name"
                                                                maxlength="250" autocomplete="off" />
                                                            <div class="custom-error" v-if="v$.aliasname.$error">
                                                                {{ v$.aliasname.$errors[0].$message }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="card-template mt-3">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <div class="custom-form-group">
                                                            <label for="formmessageInput" class="form-label">Message <span class="text-danger">*</span></label>
                                                            <textarea type="text" v-model="smsmessage" class="form-control" id="formmessageInput"
                                                                placeholder="Enter Message" v-on:keyup="htmlconvertmsg()" autocomplete="off" rows="3" maxlength="1024"
                                                                :style="{ resize: 'vertical' }"></textarea>
                                                            <div class="custom-error" v-if="v$.smsmessage.$error">
                                                                {{ v$.smsmessage.$errors[0].$message }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="intelligent-campagin-modal-header mb-2 text-center">Message Preview</div>
                                        </div>
                                    </div>
                                    <div class="preview-card-template">
                                        <div class="preview-body-msg" v-if="smsmessage != ''">
                                            <p v-html="replacebrake(this.smsmessage)"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="showmodalloader">
                            <div class="custom-modal-spinner-loader">
                                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-lg-12 col-md-12 col-12 text-end">
                        <button type="button" class="btn modal-bulk-next-btn" style="width: 100px;" @click="updateSmsTemplateBtn()" :disabled="smsgbtnloader">
                            <span v-if="!smsgbtnloader">Submit</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="smsgbtnloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Edit SMS Template modal stat here -->
</template>

<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";

export default {
    data() {
        return {
            v$: useValidate(),
            smsCampaignList: [],
            smsRecords: 0,
            loading: false,
            newtempaltemodalstatus: false,
            selected_tab: 1,
            switchuncheck: false,
            dynamicTrueActiveValue: 1,
            dynamicFalseInactiveValue: 0,
            showconfirmprivoterloaderbtn: false,
            confirm_sms_fk: '',
            confirm_status_value: '',
            smsstatusvalue: '',
            confirm_popup_status: false,
            viewsmstemplatedetailmodalstatus: false,
            smsTemplateBody: '',
            headeraliasename: '',
            editSmsTemplateDialog: false,
            showmodalloader: false,
            smsgbtnloader: false,
            editrowfk: '',
            senderid: '',
            senderidlist: [],
            vendortemplateid: '',
            templatename: '',
            aliasname: '',
            smsmessage: '',
            smsmessageinput: '',
            duplicateTemplateMsg: '',
            activate_read_key_arr: [],
        };
    },
    ApiService: null,

    created() {
        this.ApiService = new ApiService();
    },
    validations() {
        return {
            senderid: {
                required: helpers.withMessage("Please select sender id", required),
            },
            vendortemplateid: {
                required: helpers.withMessage("Please enter vendor template id", required),
                minLength: helpers.withMessage(
            "Template id should be min 10 length",
            minLength(10)
          ),
            },
            templatename: {
                required: helpers.withMessage("Please enter template name", required),
            },
            aliasname: {
                required: helpers.withMessage("Please enter alias name", required),
            },
            smsmessage: {
                required: helpers.withMessage("Please enter message", required),
            },
        };
    },
    mounted() {
        this.getsmscampaigntemplates();
    },

    methods: {
        activateReadMore(e) {
            this.activate_read_key_arr.push(e);
        },

        deactivateReadMore(e) {
            var index = this.activate_read_key_arr.indexOf(e);
            if (index !== -1) {
                this.activate_read_key_arr.splice(index, 1);
            }
        },
        confirmModalOpen(e) {
            this.confirm_sms_fk = e.cj1;
            this.smsstatusvalue = e.cj12
            this.confirm_popup_status = true;
            this.confirm_status_value = '';
        },
        confirmModalClose() {
            this.confirm_popup_status = false;
            this.confirm_sms_fk = '';
            this.confirm_status_value = '';
            this.getsmscampaigntemplates();
        },
        changeStatus() {
            let fields = {};
            fields["cj1"] = this.confirm_sms_fk;
            if (this.confirm_status_value == 0 || this.confirm_status_value == false) {
                fields["cj12"] = this.smsstatusvalue;
            }
            if (this.confirm_status_value == 1 || this.confirm_status_value == true) {
                fields["cj12"] = this.smsstatusvalue;
            }
            this.ApiService.changeSmsCampaignTemplateStatus(fields).then((items) => {
                if (items.status === 200) {
                    var successMsg = items.message;
                    this.$toast.open({
                        message: successMsg,
                        type: "success",
                        duration: 3000,
                        position: "top-right",
                    });
                    this.getsmscampaigntemplates();
                    this.confirm_sms_fk = '';
                    this.voteridToSwitch = '';
                    this.confirm_status_value = '';
                    this.smsstatusvalue = '';
                    this.confirm_popup_status = false;
                } else {
                    var errorMsg = items.message;
                    this.$toast.open({
                        message: errorMsg,
                        type: "error",
                        duration: 3000,
                        position: "top-right",
                    });
                }
            });
        },
        selectedtab(e) {
            this.selected_tab = e;
        },
        newtemplateModalOpen() {
            this.$store.dispatch("addCampaignStatus");
            // this.newtempaltemodalstatus = true;
            this.selected_tab = 4;
            this.getwhatsappcampaigntemplateList();
            this.getcallcampaigntemplateList();
        },
        newtemplateModalClose() {
            // this.newtempaltemodalstatus = false;
            this.$store.dispatch("addCampaignStatus");
        },
        getsmscampaigntemplates(item) {
            this.loading = true;
            this.activate_read_key_arr = [];
            this.ApiService.getsmscampaigntemplates(item).then((data) => {
                if (data.success === true) {
                    this.smsCampaignList = data.data;
                    this.smsRecords = data.count;
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.smsCampaignList = [];
                    this.smsRecords = 0;
                }
            });
        },
        changePage(event) {
            this.page_no = event.page;
            this.getsmscampaigntemplates({ page_no: this.page_no });
        },
        redirectIntelligentCampaignApi() {
            this.$router.push("/campaigntemplate/intelligent");
        },
        redirectWhatsAppCampaignApi() {
            this.$router.push("/campaigntemplate/whatsapp");
        }, redirectiMessageCampaignApi() {
            this.$router.push("/campaigntemplate/rcsimessage");
        }, redirectEmailCampaignApi() {
            this.$router.push("/campaigntemplate/email");
        }, redirectAudioCallCampaignApi() {
            this.$router.push("/campaigntemplate/audiocall");
        }, redirectNotificationCampaignApi() {
            this.$router.push("/campaigntemplate/pushnotification");
        },

        getwhatsappcampaigntemplateList() {
            this.ApiService.getwhatsappcampaigntemplateList().then((data) => {
                if (data.success === true) {
                    localStorage.setItem("whatsapp_templates", JSON.stringify(data.data));
                } else {
                    localStorage.setItem("whatsapp_templates", []);
                }
            });
        },
        getcallcampaigntemplateList() {
            this.ApiService.getcallcampaigntemplateList().then((data) => {
                if (data.success === true) {
                    localStorage.setItem("call_templates", JSON.stringify(data.data));
                } else {
                    localStorage.setItem("call_templates", []);
                }
            });
        },
        viewSmsDetailModalOpen(e) {
            this.viewsmstemplatedetailmodalstatus = true;
            this.smsTemplateBody = e;
            this.headeraliasename = e.cj4;
        },
        viewSmsDetailModalClose() {
            this.viewsmstemplatedetailmodalstatus = false;
            this.smsTemplateBody = '';
            this.headeraliasename = '';
        },
        editSmsTemplateModalOpen(data) {
            
            this.editSmsTemplateDialog = true;
            this.getSenderIdList();
            this.showmodalloader = true;
            this.editrowfk = '';
            this.senderid = '';
            this.vendortemplateid = '';
            this.templatename = '';
            this.aliasname = '';
            this.smsmessage = '';
            this.smsmessageinput = '';
            this.duplicateTemplateMsg = '';
            this.editrowfk = data.cj1;
            this.senderid = { value: data.cj2, label: data.cj3 };
            this.vendortemplateid = data.cj6;
            this.templatename = data.cj5;
            this.aliasname = data.cj4;
            this.smsmessage = data.cj7;
        },
        editSmsTemplateModalClose() {
            this.editSmsTemplateDialog = false;
        },
        getSenderIdList(e) {
            this.ApiService.getSenderIdList(e).then((data) => {
                if (data.status == 200) {
                    this.senderidlist = data.data;
                    this.showmodalloader = false;
                } else {
                    this.senderidlist = [];
                    this.showmodalloader = false;
                }
            });
        },
        updateSmsTemplateBtn() {
            this.v$.$validate();
            let fields = {};
            fields["cj1"] = this.editrowfk;
            if(this.senderid) {
                fields["cj2"] = this.senderid.value;
                fields["cj3"] = this.senderid.label;
            }
            fields["cj6"] = this.vendortemplateid;
            fields["cj5"] = this.templatename;
            fields["cj4"] = this.aliasname;
            fields["cj7"] = this.smsmessage;
            if (!this.v$.$error && !this.duplicateTemplateMsg) {
                this.smsgbtnloader = true;
                this.ApiService.addSmsCampaignTemplate(fields).then((data) => {
                    if (data.status == 200) {
                        this.smsgbtnloader = false;
                        var successMsg = data.message;
                        this.$toast.open({
                            message: successMsg,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.editSmsTemplateDialog = false;
                        this.getsmscampaigntemplates();
                    } else {
                        this.smsgbtnloader = false;
                        var errorMsg = data.message;
                        this.$toast.open({
                            message: errorMsg,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.editSmsTemplateDialog = true;
                    }
                });

            } else {
                this.$error;
            }
        },
        replacebrake(html) {
            const doc = html;
            return doc.replace(/(\r)*\n/g, '<br>')
        },
        htmlconvertmsg() {
            var messagevalue = this.smsmessage;
            this.smsmessage = messagevalue.replace(/\n/g, '<br/>');
        },
        onPasteMobile(event) {
            let mobvalue = event.clipboardData.getData('text');
            if (/^[0-9]+$/.test(mobvalue)) return true;
            else event.preventDefault();
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
        },
    },
};
</script>

<style scoped>
.custom-start-menu-col {
    width: 21%;
}

.custom-end-body-col {
    width: 79%;
    padding-left: 0;
}

.custom-vertical-modal-tab {
    width: 213px;
    min-width: 213px;
    height: 460px;
}

.voter-exit-box-outer {
    padding: 16px 16px 0;
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
}

.customer-label-group {
    margin-bottom: 16px;
}

.customer-label-group .from-label-value {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
}

.customer-label-group .form-label {
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 13px;
    margin-bottom: 6px;
}
.modal-body-fixed-height {
    min-height: 422px;
}

.card-template {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c1d4f3;
    border-radius: 4px;
    padding: 14px 15px 0px 15px;
}

.preview-card-template {
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #c1d4f3;
    border-radius: 4px;
    padding: 14px 15px 14px 15px;
    height: auto;
    min-height: 197px;
}

.preview-body-msg {
    font-family: "AcuminPro-Regular";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 17px;
    margin-top: 15px;
}

.preview-footer-msg {
    margin-top: 15px;
}

.showmorebody {
    color: #3f51b5 !important;
    cursor: pointer;
}

.showlessbody {
    color: #3f51b5 !important;
    cursor: pointer;
}
</style>